import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Header from './components/Header';
import SideBar from './components/SideBar';
import Staff from './components/Staff';
import Orphans from './components/Orphans';
import Events from './components/Events';
import AddOrphan from './components/AddOrphan';
import Widows from './components/Widows';
import Elders from './components/Elders';
import EventImage from './components/EventImage';



const isAuthenticated = () => {
  return !!localStorage.getItem('token');
};

// ProtectedRoute HOC to conditionally render the route
const ProtectedRoute = ({ element, ...rest }) => {
  return isAuthenticated() ? element : <Navigate to="/login" />;
};

const App = () => {
  const [timeoutId, setTimeoutId] = useState(null);

  const handleUserActivity = () => {
    // Reset the timeout on user activity
    clearTimeout(timeoutId);
    const newTimeoutId = setTimeout(logoutUser, 35 * 60 * 1000); // 35 minutes
    setTimeoutId(newTimeoutId);
  };

  const logoutUser = () => {
    // Clear the token and redirect to the login page
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  useEffect(() => {
    // Set up event listeners for user activity
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);

    // Set the initial timeout
    const initialTimeoutId = setTimeout(logoutUser, 30 * 60 * 1000); // 30 minutes
    setTimeoutId(initialTimeoutId);

    // Clean up event listeners and timeout on component unmount
    return () => {
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
      clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  return (

    <BrowserRouter basename=''>
      <div className="preloader">
        <div className="lds-ripple">
          <div className="lds-pos"></div>
          <div className="lds-pos"></div>
        </div>
      </div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />

        <Route
          path="*"
          element={
            <div className='App' id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebartype="full"
              data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
              <ProtectedRoute
                element={<>
                  <Header />
                  <SideBar />
                  <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/staff" element={<ProtectedRoute element={<Staff />} />} />
                    <Route path="/orphans" element={<ProtectedRoute element={<Orphans />} />} />
                    <Route path="/addorphan" element={<ProtectedRoute element={<AddOrphan />} />} />
                    <Route path="/widows" element={<ProtectedRoute element={<Widows />} />} />
                    <Route path="/elders" element={<ProtectedRoute element={<Elders />} />} />
                    <Route path="/events" element={<ProtectedRoute element={<Events />} />} />
                    <Route path="/eventimage" element={<ProtectedRoute element={<EventImage />} />} />

                  </Routes>
                </>}
              />
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
