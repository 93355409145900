import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  TablePagination,
  TextField,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

const useWidowsData = () => {
  const [widows, setWidows] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchWidowsData = async () => {
    try {
      const response = await axios.get('https://backend.nuwarisha.org/api/widows');
      setWidows(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching widows:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWidowsData();
  }, []);

  return { widows, loading, fetchWidowsData };
};

const WidowsTable = () => {
  const { widows, loading, fetchWidowsData } = useWidowsData();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');

  const [isAddModalOpen, setAddModalOpen] = useState(false);

  const [newWidow, setNewWidow] = useState({
    fullname: '',
    age: 0,
    address: '',
    nofchildren: 0,
    relative: '',
    phone_number: '',
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchWidowsDataMemoized = useMemo(() => fetchWidowsData, []);

  const filteredWidows = useMemo(() => {
    return widows.filter((widow) =>
      Object.values(widow).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [widows, searchTerm]);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredWidows.length - page * rowsPerPage);

  const handleAddClick = async () => {
    try {
      const response = await fetch('https://backend.nuwarisha.org/api/widows', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newWidow),
      });

      if (!response.ok) {
        throw new Error('Failed to add widow');
      }

      const responseData = await response.json();
      setWidows((prevWidows) => [...prevWidows, responseData]);
      setNewWidow({
        fullname: '',
        age: 0,
        address: '',
        nofchildren: 0,
        relative: '',
        phone_number: '',
      });
      setAddModalOpen(false);
    } catch (error) {
      console.error('Error adding widow:', error);
    }
  };


  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [widowToDelete, setWidowToDelete] = useState(null);

  const handleDeleteClick = (widow) => {
    setWidowToDelete(widow);
    setDeleteConfirmationOpen(true);
  };


  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(`https://backend.nuwarisha.org/api/widows/${widowToDelete}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`Failed to delete widow: ${response.status} ${response.statusText}`);
      }
  
      // Log the response data
      const responseData = await response.json();
  
      // Update the state to reflect the deletion
      setWidows((prevWidows) => prevWidows.filter((widow) => widow !== widowToDelete));
      setDeleteConfirmationOpen(false);
      setWidowToDelete(null);
    } catch (error) {
      console.error('Error deleting widow:', error);
      // Handle errors as needed
      setDeleteConfirmationOpen(false);
    }
  };
  


  const handleCancelDelete = () => {
    setDeleteConfirmationOpen(false);
    setWidowToDelete(null);
  };



  return (
    <div>
      <TextField
        label="Search"
        variant="outlined"
        margin="normal"
        size="small"
        width="12"
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={() => setAddModalOpen(true)}
        style={{ float: 'right', margin: '12px' }}
      >
        <i className="fas fa-plus-circle"></i> New Widow
      </button>

      {loading ? (
        // Display loading indicator while data is being fetched
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: '#f0f0f0' }}>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Full Name </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}> Age</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Address</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>No. of Children</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Relative</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}> Phone Number</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredWidows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((widow) => (
                  <TableRow key={widow.id}>
                    <TableCell>{widow.fullname}</TableCell>
                    <TableCell>{widow.age}</TableCell>
                    <TableCell>{widow.address}</TableCell>
                    <TableCell>{widow.nofchildren}</TableCell>
                    <TableCell>{widow.relative}</TableCell>
                    <TableCell>{widow.phone_number}</TableCell>
                    <TableCell>
                      <button type="button" className="btn btn-secondary btn-sm">
                        <i className="fas fa-edit"></i>
                      </button>
                      <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDeleteClick(widow.id)}>
                        <i className="fas fa-trash-alt"></i>
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredWidows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}

      {/* AddWidowModal */}
      <Dialog open={isAddModalOpen} onClose={() => setAddModalOpen(false)}>
        <DialogTitle>Add New Widow</DialogTitle>
        <DialogContent>
          <TextField
            label="Full Name"
            name="fullname"
            value={newWidow.fullname}
            onChange={(e) => setNewWidow({ ...newWidow, fullname: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Age"
            name="age"
            value={newWidow.age}
            onChange={(e) => setNewWidow({ ...newWidow, age: e.target.value })}
            fullWidth
            margin="normal"
            type="number"
          />
          <TextField
            label="Address"
            name="address"
            value={newWidow.address}
            onChange={(e) => setNewWidow({ ...newWidow, address: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="No. of Children"
            name="nofchildren"
            value={newWidow.nofchildren}
            onChange={(e) => setNewWidow({ ...newWidow, nofchildren: e.target.value })}
            fullWidth
            margin="normal"
            type="number"
          />
          <TextField
            label="Relative"
            name="relative"
            value={newWidow.relative}
            onChange={(e) => setNewWidow({ ...newWidow, relative: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Phone Number"
            name="phone_number"
            value={newWidow.phone_number}
            onChange={(e) => setNewWidow({ ...newWidow, phone_number: e.target.value })}
            fullWidth
            margin="normal"
          />
          {/* Add more fields based on your widow data structure */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddModalOpen(false)}>Cancel</Button>
          <Button onClick={handleAddClick} color="primary">
            Add Widow
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmationOpen} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete the widow?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WidowsTable;
