import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  TablePagination,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
} from '@mui/material';

const EldersTable = () => {
  const [elders, setElders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [newElder, setNewElder] = useState({
    fullname: '',
    gender: '',
    age: '',
    disability: '',
    address: '',
    phone_no: '',
  });
  const [isSuccessAlertOpen, setSuccessAlertOpen] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedElderId, setSelectedElderId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://backend.nuwarisha.org/api/elders');
        setElders(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching elders:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = useCallback((field, value) => {
    setNewElder((prevElder) => ({ ...prevElder, [field]: value }));
  }, []);

  const handleAddElder = useCallback(async () => {
    try {
      const response = await axios.post('https://backend.nuwarisha.org/api/elders', newElder);

      if (response.status !== 200) {
        throw new Error('Failed to add elder');
      }

      const responseData = response.data;
      setElders((prevElders) => [...prevElders, responseData]);

        setAddModalOpen(false);

      setNewElder({
        fullname: '',
        gender: '',
        age: '',
        disability: '',
        address: '',
        phone_no: '',
      });
    } catch (error) {
      console.error('Error adding elder:', error);
    }
  }, [newElder]);

  const handleOpenConfirmationModal = useCallback((elderId) => {
    setSelectedElderId(elderId);
    setConfirmationModalOpen(true);
  }, []);

  const handleDeleteElder = useCallback(async () => {
    try {
      const response = await axios.delete(`https://backend.nuwarisha.org/api/elders/${selectedElderId}`);

      if (response.status !== 200) {
        throw new Error('Failed to delete elder');
      }

      setSelectedElderId(null);
      setConfirmationModalOpen(false);

      const updatedResponse = await axios.get('https://backend.nuwarisha.org/api/elders');
      setElders(updatedResponse.data);
    } catch (error) {
      console.error('Error deleting elder:', error);
    }
  }, [selectedElderId]);

  const handleCloseConfirmationModal = useCallback(() => {
    setConfirmationModalOpen(false);
    setSelectedElderId(null);
  }, []);

  const handleDebouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
    }, 500),
    []
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredElders = useMemo(() => {
    return elders.filter((elder) =>
      Object.values(elder).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [elders, searchTerm]);

  const debouncedSearchTerm = useCallback(
    (value) => handleDebouncedSearch(value),
    [handleDebouncedSearch]
  );

  return (
    <div>
      <TextField
        label="Search"
        variant="outlined"
        margin="normal"
        size="small"
        onChange={(e) => debouncedSearchTerm(e.target.value)}
      />
      <button
        type="button"
        className="btn btn-primary btn-sm"
        style={{ float: 'right', margin: '12px' }}
        onClick={() => setAddModalOpen(true)}
      >
        <i className="fas fa-plus-circle"></i> New Elder
      </button>
      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: '#f0f0f0' }}>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>No</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Full Name</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Gender</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Age</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Disability</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Address</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Phone Number</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredElders
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((elder, index) => (
                  <TableRow key={elder.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{elder.fullname}</TableCell>
                    <TableCell>{elder.gender}</TableCell>
                    <TableCell>{elder.age}</TableCell>
                    <TableCell>{elder.disability}</TableCell>
                    <TableCell>{elder.address}</TableCell>
                    <TableCell>{elder.phone_no}</TableCell>
                    <TableCell>
                      <button type="button" className="btn btn-secondary btn-sm">
                        <i className="fas fa-edit"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        onClick={() => handleOpenConfirmationModal(elder.id)}
                      >
                        <i className="fas fa-trash-alt"></i>
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredElders.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}

      <Snackbar
        open={isSuccessAlertOpen}
        autoHideDuration={3000}
        onClose={() => setSuccessAlertOpen(false)}
        message="Elder added successfully"
      />

 {/* AddElderModal */}
<Dialog open={isAddModalOpen} onClose={() => setAddModalOpen(false)}>
  <DialogTitle>Add New Elder</DialogTitle>
  <DialogContent>
    <TextField
      label="Full Name"
      name="fullname"
      value={newElder.fullname}
      onChange={(e) => handleInputChange('fullname', e.target.value)}
      fullWidth
      margin="normal"
    />
    <FormControl fullWidth margin="normal">
      <InputLabel id="gender-label">Gender</InputLabel>
      <Select
        labelId="gender-label"
        id="gender"
        value={newElder.gender}
        onChange={(e) => handleInputChange('gender', e.target.value)}
      >
        <MenuItem value="Male">Male</MenuItem>
        <MenuItem value="Female">Female</MenuItem>
      </Select>
    </FormControl>
    <TextField
      label="Age"
      name="age"
      value={newElder.age}
      onChange={(e) => handleInputChange('age', e.target.value)}
      fullWidth
      margin="normal"
      type="number"
    />
    <TextField
      label="Disability"
      name="disability"
      value={newElder.disability}
      onChange={(e) => handleInputChange('disability', e.target.value)}
      fullWidth
      margin="normal"
    />
    <TextField
      label="Address"
      name="address"
      value={newElder.address}
      onChange={(e) => handleInputChange('address', e.target.value)}
      fullWidth
      margin="normal"
    />
    <TextField
      label="Phone Number"
      name="phone_no"
      value={newElder.phone_no}
      onChange={(e) => handleInputChange('phone_no', e.target.value)}
      fullWidth
      margin="normal"
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setAddModalOpen(false)}>Cancel</Button>
    <Button onClick={handleAddElder} color="primary">
      Add Elder
    </Button>
  </DialogActions>
</Dialog>


      {/* Confirmation Modal */}
      <Dialog open={isConfirmationModalOpen} onClose={handleCloseConfirmationModal}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this elder?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmationModal}>No</Button>
          <Button onClick={handleDeleteElder} color="primary" style={{ backgroundColor: '#fc3d39', color: 'white' }}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EldersTable;

function debounce(func, delay) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
