import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { InputGroup, FormControl, Button, Pagination, Modal, Form } from 'react-bootstrap';
import EventImage from './EventImage';

export default function Event() {
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [eventsPerPage] = useState(4);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [eventIdToDelete, setEventIdToDelete] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [eventData, setEventData] = useState({
    title: '',
    date: '',
    description: '',
    image: null,
    previewImage: null,
  });

  const [showEditModal, setShowEditModal] = useState(false);
  const [editFormData, setEditFormData] = useState({
    title: '',
    date: '',
    description: '',
    image: null,
    previewImage: null,
  });


  const [editFormErrors, setEditFormErrors] = useState({
    title: '',
    date: '',
    description: '',
    image: '',
  });

  const validateEditForm = () => {
    const errors = {};
    if (!editFormData.title) {
      errors.title = 'Title is required';
    }
    if (!editFormData.date) {
      errors.date = 'Date is required';
    }
    if (!editFormData.description) {
      errors.description = 'Description is required';
    }
    if (!editFormData.image) {
      errors.image = 'Image is required';
    }
    // You can add more validation rules as needed
    setEditFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const [validationErrors, setValidationErrors] = useState({
    title: '',
    date: '',
    description: '',
    image: '',
  });

  const handleEditClick = (eventId) => {
    const eventToEdit = events.find((event) => event.id === eventId);
    setEditFormData({
      title: eventToEdit.title,
      date: eventToEdit.date,
      description: eventToEdit.description,
      image: null, // Clear the image initially
      previewImage: eventToEdit.image, // Display the existing image
    });
    setShowEditModal(true);
  };

  const handleConfirmEdit = async () => {
    const formData = new FormData();
    formData.append('title', editFormData.title);
    formData.append('date', editFormData.date);
    formData.append('description', editFormData.description);
    formData.append('image', editFormData.image);

    // Validate the form data
    const errors = validateForm(editFormData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    try {
      // Replace the following line with the actual logic to send the edited data to your API
      // Example:
      const eventId = editFormData.id; // Replace with the actual ID of the event being edited
      await axios.put(`https://backend.nuwarisha.org/api/events/${eventId}`, formData);

      // Refresh the events after editing
      const response = await axios.get('https://backend.nuwarisha.org/api/events');
      setEvents(response.data);
      setFilteredEvents(response.data);
      setShowEditModal(false);
    } catch (error) {
      console.error('Error editing event:', error);
    }
  };

  const handleImageChangeEdit = (e) => {
    const imageFile = e.target.files[0];

    // Update the preview image
    const reader = new FileReader();
    reader.onloadend = () => {
      setEditFormData((prevData) => ({
        ...prevData,
        image: imageFile,
        previewImage: reader.result,
      }));
    };

    if (imageFile) {
      reader.readAsDataURL(imageFile);
    }
  };

  const handleDeleteClick = (eventId) => {
    setEventIdToDelete(eventId);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`https://backend.nuwarisha.org/api/events/${eventIdToDelete}`);
      setEvents((prevEvents) => prevEvents.filter((event) => event.id !== eventIdToDelete));
      axios.get('https://backend.nuwarisha.org/api/events')
        .then(response => {
          setEvents(response.data);
          setFilteredEvents(response.data);
        });
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  const handleCancelDelete = () => {
    setEventIdToDelete(null);
    setShowDeleteModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
    // Reset the eventData and validation errors when the modal is shown
    setEventData({
      title: '',
      date: '',
      description: '',
      image: null,
      previewImage: null,
    });
    setValidationErrors({
      title: '',
      date: '',
      description: '',
      image: '',
    });
  };

  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    axios.get('https://backend.nuwarisha.org/api/events')
      .then(response => {
        setEvents(response.data);
        setFilteredEvents(response.data);
      })
      .catch(error => {
        console.error('Error fetching events:', error);
      });
  }, []);

  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = filteredEvents.slice(indexOfFirstEvent, indexOfLastEvent);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearch = () => {
    const filtered = events.filter(event =>
      event.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredEvents(filtered);
    setCurrentPage(1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventData((prevData) => ({ ...prevData, [name]: value }));
    // Clear validation error when the field is updated
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];

    // Update the preview image
    const reader = new FileReader();
    reader.onloadend = () => {
      setEventData((prevData) => ({
        ...prevData,
        image: imageFile,
        previewImage: reader.result,
      }));
    };

    if (imageFile) {
      reader.readAsDataURL(imageFile);
    }

    // Clear validation error when the field is updated
    setValidationErrors((prevErrors) => ({ ...prevErrors, image: '' }));
  };

  const handleAddEventClick = async () => {
    // Validate the form data
    const errors = validateForm(eventData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    // Add logic to handle adding the new event using the eventData
    try {
      // Example: Send the eventData to the server
      const formData = new FormData();
      formData.append('title', eventData.title);
      formData.append('date', eventData.date);
      formData.append('description', eventData.description);
      formData.append('image', eventData.image);

      await axios.post('https://backend.nuwarisha.org/api/events', formData);
      // Refresh the events after adding
      const response = await axios.get('https://backend.nuwarisha.org/api/events');
      setEvents(response.data);
      setFilteredEvents(response.data);
    } catch (error) {
      console.error('Error adding event:', error);
    }

    // Close the modal
    setShowModal(false);
  };

  // Function to validate the form data
  const validateForm = (data) => {
    const errors = {};

    if (!data.title) {
      errors.title = 'Title is required';
    }

    if (!data.date) {
      errors.date = 'Date is required';
    }

    if (!data.description) {
      errors.description = 'Description is required';
    }

    if (!data.image) {
      errors.image = 'Image is required';
    }

    return errors;
  };

  return (
    <>
      <div className="page-wrapper">
        {/* Breadcrumb */}
        <div className="page-breadcrumb">
          <div className="row">
            <div className="col-12 d-flex no-block align-items-center">
              <h4 className="page-title">Dashboard</h4>
              <div className="ms-auto text-end">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">Dashboard</li>
                    <li className="breadcrumb-item active" aria-current="page">Events</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row el-element-overlay">
            <div className="row">
              <div className="mb-3 col-lg-3">
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Search by title..."
                    variant="outlined"
                    size="small"
                    style={{ marginBottom: 20, textAlign: 'left', width: "12", float: 'left' }}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <Button variant="outline-secondary" onClick={handleSearch}
                    style={{ marginBottom: 20, textAlign: 'left', width: "12", float: 'left' }}>
                    Search
                  </Button>
                </InputGroup>
              </div>
              <div className="col-lg-8">
                <button type="button" className="btn btn-primary btn-sm" style={{ float: 'right' }} onClick={handleShowModal}>
                  <i className="fas fa-plus-circle"></i> New Event
                </button>
              </div>
            </div>

            {currentEvents.map(event => (
              <div key={event.id} className="col-lg-3 col-md-6">
                <div className="card">
                  <div className="el-card-item">
                    {/* Replace EventImage with your actual component */}
                    <EventImage eventId={event.id} />
                    <div className="el-card-content">
                      <h4 className="mb-0">{event.title}</h4>
                      <span className="text-muted">{event.description}</span>
                    </div>
                    <div className="text-center mt-3">
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        onClick={() => handleEditClick(event.id)}
                      >
                        <i className="fas fa-edit"></i>
                      </button>
                      <button
                        type="button"
                        style={{ marginLeft: '2px' }}
                        className="btn btn-danger btn-sm"
                        onClick={() => handleDeleteClick(event.id)}
                      >
                        <i className="fas fa-trash-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* Bootstrap Modal for Confirmation */}
            <Modal show={showDeleteModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Confirm Deletion</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to delete this event?
              </Modal.Body>
              <Modal.Footer>
               

                <Button variant="secondary" onClick={handleCancelDelete}>
                  Cancel
                </Button>
                <Button variant="danger" onClick={handleConfirmDelete}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>

          {/* React Bootstrap Modal for Adding New Event */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formEventTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter event title"
                name="title"
                value={eventData.title}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.title}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.title}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formEventDate">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                name="date"
                value={eventData.date}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.date}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.date}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formEventDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter event description"
                name="description"
                value={eventData.description}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.description}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.description}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formEventImage">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                isInvalid={!!validationErrors.image}
              />
              {eventData.previewImage && (
                <img
                  src={eventData.previewImage}
                  alt="Preview"
                  style={{ marginTop: '10px', maxWidth: '100%' }}
                />
              )}
              <Form.Control.Feedback type="invalid">
                {validationErrors.image}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddEventClick}>
            Add Event
          </Button>
        </Modal.Footer>
      </Modal>

            {/* Pagination */}
            <div className="d-flex justify-content-center w-100">
              <Pagination>
                {Array.from({ length: Math.ceil(filteredEvents.length / eventsPerPage) }).map((_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
              </Pagination>

               {/* React Bootstrap Modal for Editing Existing Event */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formEditEventTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter event title"
                name="title"
                value={editFormData.title}
                onChange={(e) => setEditFormData({ ...editFormData, title: e.target.value })}
                isInvalid={!!editFormErrors.title}
              />
              <Form.Control.Feedback type="invalid">{editFormErrors.title}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formEditEventDate">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                name="date"
                value={editFormData.date}
                onChange={(e) => setEditFormData({ ...editFormData, date: e.target.value })}
                isInvalid={!!editFormErrors.date}
              />
              <Form.Control.Feedback type="invalid">{editFormErrors.date}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formEditEventDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter event description"
                name="description"
                value={editFormData.description}
                onChange={(e) => setEditFormData({ ...editFormData, description: e.target.value })}
                isInvalid={!!editFormErrors.description}
              />
              <Form.Control.Feedback type="invalid">{editFormErrors.description}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formEditEventImage">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleImageChangeEdit}
              />
              {editFormData.previewImage && (
                <img
                  src={editFormData.previewImage}
                  style={{ marginTop: '10px', maxWidth: '100%' }}
                />
              )}
            </Form.Group>
            {/* Add other form fields for editing as needed */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleConfirmEdit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
