import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ResetPasswordForm = ({ onSubmit }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Password:', password);
        console.log('Confirm Password:', confirmPassword);
        onSubmit({ email,password, confirmPassword });
    };
    

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label htmlFor="email" className="form-label">
                    Email:
                </label>
                <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>
            <div className="mb-3">
                <label htmlFor="password" className="form-label">
                    New Password:
                </label>
                <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
            </div>
            <div className="mb-3">
                <label htmlFor="confirmPassword" className="form-label">
                    Confirm Password:
                </label>
                <input
                    type="password"
                    className="form-control"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />
            </div>

            <div className="d-grid">
                <button type="submit" className="btn btn-primary btn-block">
                    Update Password
                </button>
            </div>
        </form>
    );
};

const LoginForm = ({ onSubmit }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ email, password });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label htmlFor="email" className="form-label">
                    Email:
                </label>
                <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>
            <div className="mb-3">
                <label htmlFor="password" className="form-label">
                    Password:
                </label>
                <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
            </div>
            <div className="d-grid">
                <button type="submit" className="btn btn-primary btn-block">
                    Sign In
                </button>
            </div>
        </form>
    );
};

const Login = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [resetPassword, setResetPassword] = useState(false);
    const [resetEmail, setResetEmail] = useState('');
    const currentYear = new Date().getFullYear();

    const handleLogin = async ({ email, password }) => {
        // Basic email validation
        if (!/\S+@\S+\.\S+/.test(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        try {
            const response = await fetch('https://backend.nuwarisha.org/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            if (response.ok) {
                // Store the received token in localStorage
                localStorage.setItem('token', data.token);

                console.log('Login successful!');
                setError(null);
                navigate('/dashboard');
            } else {
                setError(data.error || 'An error occurred. Please try again.');
            }
        } catch (error) {
            setError('An error occurred. Please try again later.');
            console.error(error);
        }
    };


    const handleResetPassword = async ({ email, password, confirmPassword }) => {
        // Basic password validation
        if (password != confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        try {
            const response = await fetch('https://backend.nuwarisha.org/api/reset-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({ email, password,     remember_token: ""}),
            });

            const data = await response.json();

            if (response.ok) {
                // Display a success message or redirect to a different page
                console.log('Password updated successfully!');
            } else {
                setError(data.message || 'An error occurred. Please try again.');
            }
        } catch (error) {
            setError('An error occurred. Please try again later.');
            console.error(error);
        }
    };

    useEffect(() => {
        // Check if the user is already authenticated
        if (localStorage.getItem('token')) {
            // If authenticated, redirect to a different page
            navigate('/dashboard');
        }
    }, [navigate]);

    return (
        <div className="container bgimage">
            <div className="row justify-content-center align-items-center min-vh-100">
                <div className="col-md-6">
                    <div className="card" style={{ maxWidth: '500px' }}>
                        <div className="card-body">
                            <div className="text-center">
                                <img
                                    src='assets/images/users/nuwarisha_logo.png'
                                    alt="login form"
                                    className="img-fluid"
                                    style={{ width: '80px', height: '80px' }}
                                />
                                <span className="h2 fw-bold mb-0">Nuwarisha</span>
                            </div>
                            <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: '1px' }}>
                                Sign into admin account
                            </h5>
                            <hr />
                            {resetPassword ? (
                                <ResetPasswordForm onSubmit={handleResetPassword} />
                            ) : (
                                <>
                                    <LoginForm onSubmit={handleLogin} />
                                    <a
                                        className="small text-muted mb-3"
                                        href="#!"
                                        onClick={() => setResetPassword(true)}
                                    >
                                        Forgot password?
                                    </a>
                                </>
                            )}
                            {error && (
                                <div className="alert alert-danger mt-3" role="alert">
                                    {error}
                                </div>
                            )}
                        </div>
                    </div>
                    <footer className="mt-4 text-center text-muted">
                        <p>&copy; {currentYear}  Nuwarisha Mayatima. All rights reserved.</p>
                    </footer>
                </div>
            </div>
        </div>
    );
};

export default Login;
