import React, { useState, useEffect } from 'react';
import AddStaffModal from './AddStaffModal';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Breadcrumbs, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Typography } from '@mui/material';
import StaffImage from './StaffImage';
const Staff = () => {
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [staffIdToDelete, setStaffIdToDelete] = useState(null);

    const handleDelete = (id) => {
        setStaffIdToDelete(id);
        setShowConfirmationModal(true);
    };

    const confirmDelete = () => {
        axios.delete(`https://backend.nuwarisha.org/api/staff/${staffIdToDelete}`)
            .then(response => {
                console.log('Staff member deleted successfully:', response.data);
                // Update the staff list after deletion
                setRows(rows.filter(row => row.id !== staffIdToDelete));
            })
            .catch(error => {
                console.error('Error deleting staff member:', error);
            })
            .finally(() => {
                setShowConfirmationModal(false);
            });
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        // Fetch data from the Laravel API endpoint
        axios.get('https://backend.nuwarisha.org/api/staff')
            .then(response => {
                setRows(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []); // Empty dependency array ensures the effect runs once after initial render

    const filteredRows = rows.filter(row =>
        row.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.position.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.address.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className="page-wrapper">
            {/* Breadcrumb and Search Input */}
            <div className="page-breadcrumb">
                <div className="row">
                    <div className="col-12 d-flex no-block align-items-center">
                        <h4 className="page-title">Staff</h4>
                        <div className="ms-auto text-end">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                <li className="breadcrumb-item">Dashboard</li>
                                    <li className="breadcrumb-item active" aria-current="page">Staff</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card ">
                <div class="card-body">
                    <TextField
                        label="Search"
                        variant="outlined"
                        size="small"
                        Width="12"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ marginBottom: 20, textAlign: 'left', float: 'left' }}
                    />
                    <button type="button" className="btn btn-primary btn-sm" style={{ float: 'right' }} onClick={handleOpenModal}>
                        <i className="fas fa-plus-circle"></i> Add Staff
                    </button>
                    <AddStaffModal isOpen={isModalOpen} onClose={handleCloseModal} />
                    {/* Table */}
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Picture</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Position</TableCell>
                                    <TableCell>Address</TableCell>
                                    <TableCell>Phone</TableCell>
                                    <TableCell>Action</TableCell>


                                    {/* Add more table headers if there are additional columns */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                                    <TableRow key={row.id}>
                                        <TableCell style={{ maxWidth: '80px', padding: '8px' }}>
                                            <StaffImage staffId={row.id} />
                                        </TableCell>                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.position}</TableCell>
                                        <TableCell>{row.address}</TableCell>
                                        <TableCell>{row.phone}</TableCell>
                                        <TableCell>
                                            <button type="button" class="btn btn-secondary btn-sm " style={{ marginLeft: '2px' }}><i className="fas fa-edit"></i></button>
                                            <button type="button" className="btn btn-danger btn-sm" style={{ marginLeft: '2px' }} onClick={() => handleDelete(row.id)}>
                                                <i className="fas fa-trash-alt"></i>
                                            </button>

                                        </TableCell>



                                        {/* Add more table cells if there are additional columns */}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Pagination */}
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredRows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </div>

            <div className={`modal fade ${showConfirmationModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showConfirmationModal ? 'block' : 'none' }}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Confirm Deletion</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowConfirmationModal(false)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure you want to delete this staff member?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setShowConfirmationModal(false)}>Close</button>
                            <button type="button" className="btn btn-danger" onClick={confirmDelete}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >


    );
};

export default Staff;
