import React from 'react';
import WidowsTable from './WidowsTable';

const Widows = ({ widows }) => {
  return (
    <div>
    <div className="page-wrapper">
        {/* Breadcrumb and Search Input */}
        <div className="page-breadcrumb">
            <div className="row">
                <div className="col-12 d-flex no-block align-items-center">
                    <h4 className="page-title">Widows</h4>
                    <div className="ms-auto text-end">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                            <li className="breadcrumb-item">Dashboard</li>
                                <li className="breadcrumb-item active" aria-current="page">Widows</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-body">
            <WidowsTable/>
            </div>
        </div>
    </div>
</div>
  );
};

export default Widows;
