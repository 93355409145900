import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const AddOrphans = () => {
    const [formData, setFormData] = useState({
        fname: '',
        mname: '',
        lname: '',
        gender: '',
        dob: '',
        age: '',
        disability: '',
        study: '',
        parentfullname: '',
        parentaddress: '',
        parentphone: '',
        parentgender: '',
    });

    const [validationErrors, setValidationErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const handleCloseModal = () => {
        setShowModal(false);
        // Redirect to "orphans" page after closing the modal
        navigate('/orphans');
    };

    const handleAutoCloseModal = () => {
        setTimeout(() => {
            handleCloseModal();
        }, 1500);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Handle date of birth separately to calculate age
        if (name === 'dob') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));

            // Calculate age based on the entered date of birth
            const dobDate = new Date(value);
            const today = new Date();
            const age = today.getFullYear() - dobDate.getFullYear();
            setFormData((prevData) => ({
                ...prevData,
                age: age.toString(),
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }

        // Clear validation error when the field is updated
        setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    const validateForm = () => {
        const errors = {};
        Object.keys(formData).forEach((key) => {
            if (!formData[key]) {
                errors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
            }
        });
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            return;
        }

        try {
            const response = await axios.post('https://backend.nuwarisha.org/api/orphans', formData);
            console.log('Orphan added successfully:', response.data);

            setSuccessMessage('Orphan added successfully!');
            setShowModal(true);
            handleAutoCloseModal();
        } catch (error) {
            console.error('Error adding orphan:', error);
        }
    };

    return (
        <div>
            <div className="page-wrapper">
            <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-12 d-flex no-block align-items-center">
                            <h4 className="page-title">Add Orphans</h4>
                            <div className="ms-auto text-end">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                    <li className="breadcrumb-item">Dashboard</li>
                                        <li className="breadcrumb-item active" aria-current="page">Add Orphans</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        {successMessage && (
                            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5>Success Message</h5>
                                        </div>
                                        <div className="modal-body text-center">
                                            <i className='fas fa-check-circle' style={{ color: 'green', fontSize: '4em' }}></i>
                                            <p>{successMessage}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <form onSubmit={handleSubmit}>
                            <div className="row mb-3">
                                <div className="col-lg-4">
                                    <label htmlFor="fname">First Name</label>
                                    <input type="text" className={`form-control ${validationErrors.fname ? 'is-invalid' : ''}`} placeholder="Enter First Name" id="fname" name="fname" value={formData.fname} onChange={handleChange} />
                                    {validationErrors.fname && <div className="invalid-feedback">{validationErrors.fname}</div>}
                                </div>
                                <div className="col-lg-4">
                                    <label htmlFor="mname">Middle Name</label>
                                    <input type="text" className={`form-control ${validationErrors.mname ? 'is-invalid' : ''}`} placeholder="Enter Middle Name" id="mname" name="mname" value={formData.mname} onChange={handleChange} />
                                    {validationErrors.mname && <div className="invalid-feedback">{validationErrors.mname}</div>}
                                </div>
                                <div className="col-lg-4">
                                    <label htmlFor="lname">Last Name</label>
                                    <input type="text" className={`form-control ${validationErrors.lname ? 'is-invalid' : ''}`} placeholder="Enter Last Name" id="lname" name="lname" value={formData.lname} onChange={handleChange} />
                                    {validationErrors.lname && <div className="invalid-feedback">{validationErrors.lname}</div>}
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-lg-4">
                                    <label htmlFor="gender">Gender</label>
                                    <select className={`form-control ${validationErrors.gender ? 'is-invalid' : ''}`} id="gender" name="gender" value={formData.gender} onChange={handleChange}>
                                        <option value="">Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                    {validationErrors.gender && <div className="invalid-feedback">{validationErrors.gender}</div>}
                                </div>
                                <div className="col-lg-4">
                                    <label htmlFor="dob">Date of Birth</label>
                                    <input type="date" className={`form-control ${validationErrors.dob ? 'is-invalid' : ''}`} id="dob" name="dob" value={formData.dob} onChange={handleChange} />
                                    {validationErrors.dob && <div className="invalid-feedback">{validationErrors.dob}</div>}
                                </div>
                                <div className="col-lg-4">
                                    <label htmlFor="age">Age</label>
                                    <input type="number" className={`form-control ${validationErrors.age ? 'is-invalid' : ''}`} id="age" name="age" value={formData.age} onChange={handleChange} disabled />
                                    {validationErrors.age && <div className="invalid-feedback">{validationErrors.age}</div>}
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-lg-4">
                                    <label htmlFor="disability">Disability</label>
                                    <input type="text" className={`form-control ${validationErrors.disability ? 'is-invalid' : ''}`} id="disability" name="disability" value={formData.disability} onChange={handleChange} />
                                    {validationErrors.disability && <div className="invalid-feedback">{validationErrors.disability}</div>}
                                </div>
                                <div className="col-lg-4">
                                    <label htmlFor="study">Study</label>
                                    <input type="text" className={`form-control ${validationErrors.study ? 'is-invalid' : ''}`} id="study" name="study" value={formData.study} onChange={handleChange} />
                                    {validationErrors.study && <div className="invalid-feedback">{validationErrors.study}</div>}
                                </div>
                                <div className="col-lg-4">
                                    <label htmlFor="parentfullname">Parent Full Name</label>
                                    <input type="text" className={`form-control ${validationErrors.parentfullname ? 'is-invalid' : ''}`} id="parentfullname" name="parentfullname" value={formData.parentfullname} onChange={handleChange} />
                                    {validationErrors.parentfullname && <div className="invalid-feedback">{validationErrors.parentfullname}</div>}
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-lg-4">
                                    <label htmlFor="parentaddress">Parent Address</label>
                                    <input type="text" className={`form-control ${validationErrors.parentaddress ? 'is-invalid' : ''}`} id="parentaddress" name="parentaddress" value={formData.parentaddress} onChange={handleChange} />
                                    {validationErrors.parentaddress && <div className="invalid-feedback">{validationErrors.parentaddress}</div>}
                                </div>
                                <div className="col-lg-4">
                                    <label htmlFor="parentphone">Parent Phone</label>
                                    <input type="text" className={`form-control ${validationErrors.parentphone ? 'is-invalid' : ''}`} id="parentphone" name="parentphone" value={formData.parentphone} onChange={handleChange} />
                                    {validationErrors.parentphone && <div className="invalid-feedback">{validationErrors.parentphone}</div>}
                                </div>
                                <div className="col-lg-4">
                                    <label htmlFor="parentgender">Parent Gender</label>
                                    <select className={`form-control ${validationErrors.parentgender ? 'is-invalid' : ''}`} id="parentgender" name="parentgender" value={formData.parentgender} onChange={handleChange}>
                                        <option value="">Select Parent Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                    {validationErrors.parentgender && <div className="invalid-feedback">{validationErrors.parentgender}</div>}
                                </div>
                            </div>

                            <div className="form-group">
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                                <Link to="/orphans" className="btn btn-secondary ms-2">
                                    Cancel
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddOrphans;
