import React, { useState, useEffect } from 'react';
import axios from 'axios';

const StaffImage = ({ staffId }) => {
  const [staffImage, setStaffImage] = useState(null);

  useEffect(() => {
    const fetchStaffImage = async () => {
      try {
        const response = await axios.get(`https://backend.nuwarisha.org/api/staff/${staffId}/image`);
        setStaffImage(`data:image/jpeg;base64,${response.data.image}`);
      } catch (error) {
        console.error('Error fetching staff image:', error);
      }
    };

    fetchStaffImage();
  }, [staffId]);

  return (
    <div className="text-left">
    {staffImage ? (
      <img
        src={staffImage}
        alt="Staff"
        className="img-fluid"
        style={{ maxWidth: '100%', maxHeight: '100%', height: 'auto' }}
      />
    ) : (
      <p>no image!</p>
    )}
  </div>
  
  
  
  );
};

export default StaffImage;
