import React, { useState } from 'react';
import { Modal, Box, TextField, Button } from '@mui/material';
import axios from 'axios';


const AddStaffModal = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        position: '',
        address: '',
        phone: '',
        picture: '', // Store the file here
        // Add more fields as needed
    });

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
            const apiEndpoint = 'https://backend.nuwarisha.org/api/staff';

            const formDataToSend = new FormData();
            formDataToSend.append('name', formData.name);
            formDataToSend.append('position', formData.position);
            formDataToSend.append('address', formData.address);
            formDataToSend.append('phone', formData.phone);
            formDataToSend.append('picture', formData.picture);

            const response = await axios.post(apiEndpoint, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('Response from API:', response.data);

            // Reset form data and close the modal after successful submission
            setFormData({
                name: '',
                position: '',
                address: '',
                phone: '',
                picture: '',
                // Reset additional fields as needed
            });
            onClose();
        } catch (error) {
            // Handle errors (e.g., show an error message to the user)
            console.error('Error:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;

        if (name === 'picture') {
            // Handle file upload for Date of Birth field
            setFormData({
                ...formData,
                [name]: files[0], // Store the first selected file
            });
        } else {
            // Handle other input fields
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };


    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <h2>Add Staff</h2>
                <form onSubmit={handleFormSubmit}>
                    {/* Form fields for staff details */}
                    <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                    />
                    <TextField
                        label="Position"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="position"
                        value={formData.position}
                        onChange={handleInputChange}
                        required
                    />
                    <TextField
                        label="Address"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="address"
                        value={formData.address}
                        onChange={handleInputChange}
                        required
                    />
                    <TextField
                        label="Phone"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        required
                    />
                    <TextField
                        label="Upload Photo"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="picture"
                        type="file"
                        onChange={handleInputChange}
                        inputProps={{ accept: 'image/*' }} // Allow only image files
                        required
                    />
                    {/* Add more form fields as needed */}
                    <Button type="submit" variant="contained" color="primary" style={{ marginRight: 10 }}>
                        Save changes
                    </Button>
                    <Button variant="contained" color="secondary" onClick={onClose}>
                        Close
                    </Button>
                </form>
            </Box>
        </Modal>
    );
};

export default AddStaffModal;
