import React from 'react'
import { Link } from 'react-router-dom';
import OrphansTable from './OrphansTable';

export default function
    () {
    return (
        <div>
            <div className="page-wrapper">
                {/* Breadcrumb and Search Input */}
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-12 d-flex no-block align-items-center">
                            <h4 className="page-title">Orphans</h4>
                            <div className="ms-auto text-end">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                    <li className="breadcrumb-item">Dashboard</li>
                                        <li className="breadcrumb-item active" aria-current="page">Orphans</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <OrphansTable />

                    </div>
                </div>
            </div>
        </div>
    )
}
