import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// In your index.js or App.js
import 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


ReactDOM.render(
    <App />,
  document.getElementById('root')
);
