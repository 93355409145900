import React from 'react';
import { Link } from 'react-router-dom';


const SideBar = () => {
  return (
    <aside className="left-sidebar" data-sidebarbg="skin5">
      <div className="scroll-sidebar">
        <nav className="sidebar-nav">
          <ul id="sidebarnav" className="pt-4">
            <li className="sidebar-item">
              <Link
                to="/dashboard"
                className="sidebar-link waves-effect waves-dark sidebar-link"
                style={{ textDecoration: 'none' }}
                aria-expanded="false"
              >
                <i className="mdi mdi-view-dashboard"></i>
                <span className="hide-menu">Dashboard</span>
              </Link>
            </li>
            <li className="sidebar-item">
              <Link
                to="/staff"
                className="sidebar-link waves-effect waves-dark sidebar-link"
                style={{ textDecoration: 'none' }}
                aria-expanded="false"
              >
                <i className=" mdi mdi-account-multiple"></i>
                <span className="hide-menu">Staffs</span>
              </Link>
            </li>
            <li className="sidebar-item">
              <Link
                to="/orphans"
                className="sidebar-link waves-effect waves-dark sidebar-link"
                style={{ textDecoration: 'none' }}
                aria-expanded="false"
              >
                <i className="mdi mdi-human-child"></i>
                <span className="hide-menu">Orphans</span>
              </Link>
            </li>
            <li className="sidebar-item">
              <Link
                to="/widows"
                className="sidebar-link waves-effect waves-dark sidebar-link"
                style={{ textDecoration: 'none' }}
                aria-expanded="false"
              >
                <i className="mdi mdi-human-female"></i>
                <span className="hide-menu">Widows</span>
              </Link>
            </li>
            <li className="sidebar-item">
              <Link
                to="/elders"
                className="sidebar-link waves-effect waves-dark sidebar-link"
                style={{ textDecoration: 'none' }}
                aria-expanded="false"
              >
                <i className="mdi mdi-human-male-female"></i>
                <span className="hide-menu">Elders</span>
              </Link>
            </li>
            <li className="sidebar-item">
              <Link
                to="/events"
                className="sidebar-link waves-effect waves-dark sidebar-link"
                style={{ textDecoration: 'none' }}
                aria-expanded="false"
              >
                <i className="mdi mdi-chart-bar"></i>
                <span className="hide-menu">Events</span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default SideBar;
