// EventImage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EventImage = ({ eventId }) => {
  const [eventImage, setEventImage] = useState(null);

  useEffect(() => {
    const fetchEventImage = async () => {
      try {
        const response = await axios.get(`https://backend.nuwarisha.org/api/event/${eventId}/image`);
        setEventImage(`data:image/jpeg;base64,${response.data.image}`);
      } catch (error) {
        console.error('Error fetching event image:', error);
      }
    };

    fetchEventImage();
  }, [eventId]);

  return (
    <div>
      {eventImage ? (
        <img src={eventImage} className="img-fluid" alt="Event" />
      ) : (
        <p>no image!</p>
      )}
    </div>
  );
};

export default EventImage;
