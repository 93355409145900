import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TablePagination,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { styled } from '@mui/system';  // Corrected import
import axios from 'axios';
import { Link } from 'react-router-dom';

const StripedTable = styled('div')({
  '& table tbody tr:nth-child(odd)': {
    backgroundColor: (theme) => theme.palette.action.hover,
  },
});

const OrphansTable = () => {
  const [orphans, setOrphans] = useState([]);
  const [filteredOrphans, setFilteredOrphans] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingOrphanId, setDeletingOrphanId] = useState(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editingOrphan, setEditingOrphan] = useState(null);

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://backend.nuwarisha.org/api/orphans');
        setOrphans(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once

  useEffect(() => {
    // Filter the orphans based on search term
    const filtered = orphans.filter((orphan) =>
      Object.values(orphan).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredOrphans(filtered);
  }, [searchTerm, orphans]);

  useEffect(() => {
    // Calculate age when Date of Birth changes
    if (editingOrphan?.dob) {
      const age = calculateAge(editingOrphan.dob);
      setEditingOrphan((prev) => ({ ...prev, age: age }));
    }
  }, [editingOrphan?.dob]);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteOrphan = (id) => {
    setDeletingOrphanId(id);
    setShowDeleteModal(true);
  };

  const confirmDeleteOrphan = async () => {
    try {
      await axios.delete(`https://backend.nuwarisha.org/api/orphans/${deletingOrphanId}`);
      setOrphans((prevOrphans) => prevOrphans.filter((orphan) => orphan.id !== deletingOrphanId));
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error deleting orphan:', error);
    }
  };
  const confirmEditOrphan = async (updatedOrphanData) => {
    try {
      await axios.put(`https://backend.nuwarisha.org/api/orphans/${editingOrphan.id}`, updatedOrphanData);
      setOrphans((prevOrphans) =>
        prevOrphans.map((orphan) =>
          orphan.id === editingOrphan.id ? { ...orphan, ...updatedOrphanData } : orphan
        )
      );
      setShowEditModal(false);
    } catch (error) {
      console.error('Error editing orphan:', error);
    }
  };

  const handleEditOrphan = (orphan) => {
    setEditingOrphan(orphan);
    setShowEditModal(true);
  };


  const handlePrint = () => {
    const printWindow = window.open('', '_blank');

    if (printWindow) {
      printWindow.document.write('<html><head><title></title></head><body >');
      // Add company logo
      printWindow.document.write('<h2><img src="assets/images/users/nuwarisha_logo.png" alt="Company Logo" width="80px" height="80px" />Nuwarisha Mayatima</h2>');

      // Add table styling
      printWindow.document.write('<style>table { width: 100%; border-collapse: collapse; } th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }</style>');

      printWindow.document.write('<h3 style="text-align: center;">List of Orphans</h3>');
      printWindow.document.write('<table border="1">');
      printWindow.document.write('<thead><tr><th>No</th><th>First Name</th><th>Middle Name</th><th>Last Name</th><th>Gender</th><th>Age</th><th>Disability</th><th>Study</th><th>Parent Full Name</th><th>Parent Address</th><th>Parent Phone</th><th>Parent Gender</th></tr></thead><tbody>');

      let orphanCount = 0;

      orphans.forEach((orphan) => {
        orphanCount++;

        printWindow.document.write(`
          <tr>
            <td>${orphanCount}</td>
            <td>${orphan.fname}</td>
            <td>${orphan.mname}</td>
            <td>${orphan.lname}</td>
            <td>${orphan.gender}</td>
            <td>${orphan.age}</td>
            <td>${orphan.disability}</td>
            <td>${orphan.study}</td>
            <td>${orphan.parentfullname}</td>
            <td>${orphan.parentaddress}</td>
            <td>${orphan.parentphone}</td>
            <td>${orphan.parentgender}</td>
          </tr>
        `);
      });

      printWindow.document.write('</tbody></table></body></html>');
      printWindow.document.close();
      printWindow.print();
    }
  };

  return (
    <StripedTable>
      <TextField
        label="Search"
        variant="outlined"
        size="small"
        Width="12"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ margin: '16px 0' }}
      />
      <Link to="/addorphan" type="button" className="btn btn-primary btn-sm" style={{ float: 'right', margin: '12px' }} >
        <i className="fas fa-plus-circle"></i> new orphan
      </Link>
      <button type="button" className="btn btn-secondary btn-sm" onClick={handlePrint} style={{ float: 'right', margin: '12px' }} >
        <i className="fas fa-print"></i> print
      </button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead className='table' style={{ backgroundColor: '#f0f0f0' }}>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>No</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Middle Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Gender</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Age</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Disability</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Study</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Parent Full Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Parent Address</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Parent Phone</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Parent Gender</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredOrphans
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((orphan, index) => (
                <TableRow key={orphan.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{orphan.fname}</TableCell>
                  <TableCell>{orphan.mname}</TableCell>
                  <TableCell>{orphan.lname}</TableCell>
                  <TableCell>{orphan.gender}</TableCell>
                  <TableCell>{orphan.age}</TableCell>
                  <TableCell>{orphan.disability}</TableCell>
                  <TableCell>{orphan.study}</TableCell>
                  <TableCell>{orphan.parentfullname}</TableCell>
                  <TableCell>{orphan.parentaddress}</TableCell>
                  <TableCell>{orphan.parentphone}</TableCell>
                  <TableCell>{orphan.parentgender}</TableCell>
                  <TableCell>
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm"
                      onClick={() => handleEditOrphan(orphan)}
                    >
                      <i className="fas fa-edit"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() => handleDeleteOrphan(orphan.id)}
                    >
                      <i className="fas fa-trash-alt"></i>
                    </button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredOrphans.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      {/* Delete Confirmation Modal */}
      <Dialog open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this orphan?</p>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ backgroundColor: '#fc3d39', color: 'white' }}
            onClick={confirmDeleteOrphan}
          >
            Yes
          </Button>
          <Button variant="contained" onClick={() => setShowDeleteModal(false)}>
            No
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={showEditModal} onClose={() => setShowEditModal(false)}>
        <DialogTitle>Edit Orphan</DialogTitle>
        <DialogContent>
          {/* Form for editing orphan data */}
          <div style={{ display: 'flex', marginBottom: '26px', marginTop: '10px' }}>
            <TextField
              label="First Name"
              size="small"
              value={editingOrphan?.fname || ''}
              onChange={(e) => setEditingOrphan((prev) => ({ ...prev, fname: e.target.value }))}
              style={{ marginRight: '10px', flex: 1 }}
            />
            <TextField
              label="Middle Name"
              size="small"
              variant="outlined"
              value={editingOrphan?.mname || ''}
              onChange={(e) => setEditingOrphan((prev) => ({ ...prev, mname: e.target.value }))}
              style={{ marginRight: '10px', flex: 1 }}
            />
            <TextField
              label="Last Name"
              variant="outlined"
              size="small"
              value={editingOrphan?.lname || ''}
              onChange={(e) => setEditingOrphan((prev) => ({ ...prev, lname: e.target.value }))}
              style={{ flex: 1 }}
            />
          </div>
          <div style={{ display: 'flex', marginBottom: '26px', marginTop: '10px' }}>
            <FormControl variant="outlined" style={{ flex: 1, marginRight: '10px' }}>
              <InputLabel>Gender</InputLabel>
              <Select
                label="Gender"
                size="small"
                value={editingOrphan?.gender || ''}
                onChange={(e) => setEditingOrphan((prev) => ({ ...prev, gender: e.target.value }))}
                style={{ minWidth: '120px' }}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Date of Birth"
              type="date"
              variant="outlined"
              size="small"
              value={editingOrphan?.dob || ''}
              onChange={(e) => setEditingOrphan((prev) => ({ ...prev, dob: e.target.value }))}
              style={{ marginRight: '10px', flex: 1 }}
            />
            <TextField
              label="Age"
              variant="outlined"
              size="small"
              value={editingOrphan?.age || ''}
              onChange={(e) => setEditingOrphan((prev) => ({ ...prev, age: e.target.value }))}
              style={{ flex: 1 }}
              disabled
            />
          </div>
          <div style={{ display: 'flex', marginBottom: '16px', marginTop: '10px' }}>
            <TextField
              label="Disability"
              variant="outlined"
              size="small"
              fullWidth
              value={editingOrphan?.disability || ''}
              onChange={(e) => setEditingOrphan((prev) => ({ ...prev, disability: e.target.value }))}
              style={{ marginRight: '10px' }}
            />
            <TextField
              label="Study"
              variant="outlined"
              fullWidth
              size="small"
              value={editingOrphan?.study || ''}
              onChange={(e) => setEditingOrphan((prev) => ({ ...prev, study: e.target.value }))}
              style={{ marginBottom: '16px' }}
            />
          </div>
          <hr />
          <TextField
            label="Parent Full Name"
            variant="outlined"
            size="small"
            fullWidth
            value={editingOrphan?.parentfullname || ''}
            onChange={(e) => setEditingOrphan((prev) => ({ ...prev, parentfullname: e.target.value }))}
            style={{ marginBottom: '26px' }}
          />
          <TextField
            label="Parent Address"
            variant="outlined"
            size="small"
            fullWidth
            value={editingOrphan?.parentaddress || ''}
            onChange={(e) => setEditingOrphan((prev) => ({ ...prev, parentaddress: e.target.value }))}
            style={{ marginBottom: '16px' }}
          />
          <div style={{ display: 'flex', marginBottom: '16px', marginTop: '10px' }}>
            <TextField
              label="Parent Phone"
              variant="outlined"
              size="small"
              fullWidth
              value={editingOrphan?.parentphone || ''}
              onChange={(e) => setEditingOrphan((prev) => ({ ...prev, parentphone: e.target.value }))}
              style={{ marginRight: '16px' }}
            />
            <FormControl variant="outlined" fullWidth style={{ marginBottom: '16px' }}>
              <InputLabel>Parent Gender</InputLabel>
              <Select
                label="Parent Gender"
                size="small"
                value={editingOrphan?.parentgender || ''}
                onChange={(e) => setEditingOrphan((prev) => ({ ...prev, parentgender: e.target.value }))}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </Select>
            </FormControl>
          </div>
          {/* Add more fields based on your orphan data structure */}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ backgroundColor: '#fc3d39', color: 'white' }}
            onClick={() => confirmEditOrphan(editingOrphan)}
          >
            Save
          </Button>
          <Button variant="contained" onClick={() => setShowEditModal(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>



    </StripedTable >
  );
};

export default OrphansTable;
